import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { KountService } from '@ztarmobile/zwp-service-backend-v2';
import { KOUNT } from '@env/environment';

export interface InternalStateType {
  [key: string]: any;
}

@Injectable({providedIn: 'root'})
export class AppState {
  public state: InternalStateType = {};
  public loading = false;
  public isCampaign = false;
  public userLoggedIn: ReplaySubject<boolean> = new ReplaySubject(1);
  public isMarketingCampaign: Observable<boolean>;
  public isMarktingCampaignReplySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  public campaignQueryParams: Observable<any>;
  public campaignQueryParamsReplySubject: ReplaySubject<any> = new ReplaySubject(1);
  public isMoneySavingProCampaign: Observable<boolean>;
  public isMoneySavingProReplySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  public jsonLDString: any;
  public resolvedCaptchaSubject: ReplaySubject<any> = new ReplaySubject(1);
  public resolvedSecondCaptchaSubject: ReplaySubject<any> = new ReplaySubject(1);
  public resolvedCaptcha: Observable<string>;
  public resolvedSecondCaptcha: Observable<string>;
  public resetAndExecuteCaptchaSubject: ReplaySubject<{action:string}> = new ReplaySubject(1);
  public resetAndExecuteCaptcha: Observable<{action:string}>;
  public resetAndExecuteSecondCaptchaSubject: ReplaySubject<{action:string}> = new ReplaySubject(1);
  public resetAndExecuteSecondCaptcha: Observable<{action:string}>;

  private userSessionId: string;
  private jsonLd: any = {};

  constructor(private sanitizer: DomSanitizer,private kountService: KountService) {
    this.isMarketingCampaign = this.isMarktingCampaignReplySubject.asObservable();
    this.campaignQueryParams = this.campaignQueryParamsReplySubject.asObservable();
    this.isMoneySavingProCampaign = this.isMoneySavingProReplySubject.asObservable();
    this.resolvedCaptcha = this.resolvedCaptchaSubject.asObservable();
    this.resolvedSecondCaptcha = this.resolvedSecondCaptchaSubject.asObservable();
    this.resetAndExecuteCaptcha = this.resetAndExecuteCaptchaSubject.asObservable();
    this.resetAndExecuteSecondCaptcha = this.resetAndExecuteSecondCaptchaSubject.asObservable();
  }

  public clearSessionStorage(): void {
    sessionStorage.removeItem('shippingAddress');
    sessionStorage.removeItem('shippingMethod');
    sessionStorage.removeItem('payment_id');
    sessionStorage.removeItem('auto_renew');
    sessionStorage.removeItem('purchased');
    sessionStorage.removeItem('activation');
    sessionStorage.removeItem('device');
    sessionStorage.removeItem('emailMdn');
    sessionStorage.removeItem('MoneyProReferral');
    sessionStorage.removeItem('tracked');
    sessionStorage.removeItem('anonymous');
    sessionStorage.removeItem('pendingAddress');
    sessionStorage.removeItem('isMigrationSimRemoved');
  }

  public initializeKountSdk(): void {
    this.userSessionId = sessionStorage.getItem('sessionId');
    if(!this.userSessionId) {
      this.userSessionId = this.kountService.initializeKountSdk(KOUNT.clientId, KOUNT.environment);
      sessionStorage.setItem('sessionId',this.userSessionId);
    }
  }

  public setJsonLdData(data): any {
    this.jsonLd = data;
    this.jsonLd = Object.assign({'@context': 'http://schema.org/'}, this.jsonLd);
    this.jsonLDString = '<script type="application/ld+json">' + JSON.stringify(this.jsonLd) + '</script>';
    this.jsonLDString = this.sanitizer.bypassSecurityTrustHtml(this.jsonLDString);
    return this.jsonLDString;
  }
  
  public removeEmptyValues(obj): any {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmptyValues(obj[key]);
      } else if (obj[key] == null || obj[key] === '' || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }

}
