import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import {
  AccountPaymentService,
  FirebaseAccountPaymentService,
  IFirebasePaymentMethod,
  IUserPlan,
  UserAccountService,
  UserPlansService
} from '@ztarmobile/zwp-service-backend';
import { ToastrHelperService } from '../../services/toast-helper.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '@app/app.service';
import { Subscription } from 'rxjs';
import { ACCOUNT_ROUTE_URLS } from '@app/app.routes.names';
export class FreeupPaymentModalContext {
  public paymentMethodId: string;
  public userPlan: IUserPlan;
  public customClass?: string;
  public accountSettingsRoute?: string;
}

@Component({
  selector: 'app-manage-payment-modal-context',
  templateUrl: './manage-payment-modal-context.component.html'
})
export class ManagePaymentModalContextComponent implements OnInit, OnDestroy {
  public methodsList: IFirebasePaymentMethod[];
  public expiredMethodsList: IFirebasePaymentMethod[] = [];
  public nonExpiredmethodsList: Array<IFirebasePaymentMethod> = [];
  public context: any;
  public selectedMethodId: string;
  public setDefaultRequest = false;
  public currentYear;
  public currentMonth;
  
  private paymentMethodsListSubscription: Subscription;
  private currentDate: Date;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private firebaseAccountPaymentService: FirebaseAccountPaymentService,
    private toastHelper: ToastrHelperService,
    private accountPaymentService: AccountPaymentService,
    private userPlanService: UserPlansService,
    private router: Router,
    public dialog: MatDialogRef<FreeupPaymentModalContext>,
    private appState: AppState,
    private userAccountService: UserAccountService
  ) {
    this.context = data;
    this.currentDate = new Date();
    const year = parseInt(this.currentDate.getFullYear().toString().substr(-2), 10);
    this.currentMonth = this.currentDate.getMonth() + 1;
    this.currentYear = this.currentDate.getFullYear();
  }

  ngOnInit(): void {
    this.userPlanService.getUserPlan(this.context.userPlan.id).then((userPlan) => {
      this.context.userPlan = userPlan;
      this.selectedMethodId = this.context.userPlan.paymentMethodId;
    });

    this.paymentMethodsListSubscription = this.firebaseAccountPaymentService.paymentMethodsList.pipe(take(1)).subscribe((methods) => {
      this.methodsList = methods;
      if (this.methodsList?.length > 0) {
        this.currentMonth = this.currentMonth.toString();
        this.currentYear = this.currentYear.toString().slice(-2);
        if (this.currentMonth?.length === 1) {
          this.currentMonth = `0${this.currentMonth}`;
        }
        this.methodsList.map(method => {
          if (!!method && !!method?.expirationDate) {
            let cardExpMonth = method?.expirationDate?.substr(0, 2);
            let cardExpYear = method?.expirationDate?.substr(2, 2);
            // if card year less than currect year then its expired
            if (parseInt(cardExpYear, 10) < parseInt(this.currentYear, 10)) {
              this.expiredMethodsList.push(method);
              Object.assign(method, { expired: true });
            } else if (parseInt(cardExpYear, 10) <= parseInt(this.currentYear, 10) && parseInt(cardExpMonth, 10) < parseInt(this.currentMonth, 10)) {
              this.expiredMethodsList.push(method);
              Object.assign(method, { expired: true });
            } else {
              Object.assign(method, { expired: false });
              this.nonExpiredmethodsList.push(method);
            }
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if(!!this.paymentMethodsListSubscription) {
      this.paymentMethodsListSubscription?.unsubscribe();
    }
  }

  public closeDialog(methodId?: string): void {
    this.dialog.close(methodId);
  }

  public makePaymentMethodAsDefault(methodKey): void {
    if (this.setDefaultRequest) {
      return;
    }
    const userPlanId = !!this.context.userPlan ? this.context.userPlan.id : undefined;
    this.setDefaultRequest = true;
    this.appState.loading = true;
    this.accountPaymentService.setPaymentMethodAsDefault(methodKey, userPlanId).then(() => {
      this.userAccountService.syncBackendUserAccountByUserPlanId(this.context.userPlan.id, this.context.userPlan.mdn).then((data) => {
        this.setDefaultRequest = false;
        this.appState.loading = false;
        this.toastHelper.showSuccess('Default payment method set, it may take about 1 minute before the account getting the effect.');
        if(!!data?.profileKey) {
          this.closeDialog('success');
        } else {
          this.closeDialog('error');
        }
      });
    }, (error) => {
      this.setDefaultRequest = false;
      this.appState.loading = false;
      this.toastHelper.showAlert(error.message || error);
      this.closeDialog('error');
    });
  }
  public goToSettings(): void {
    this.closeDialog();
    const params = {};
    params[ACCOUNT_ROUTE_URLS.PARAMS.MANAGE_CARDS] = true;
    this.router.navigate([this.context.accountSettingsRoute, params]);
  }

  public checkBoxLabel(address: IFirebasePaymentMethod): string {
    let addressLabel = '';
    if (!!address.brand) {
      addressLabel = address.brand;
    } else {
      if (!!address.alias) {
        addressLabel = address.alias;
      }
      if (!!address.name) {
        addressLabel = address.name;
      }
    }
    return addressLabel.toLowerCase();
  }

}

